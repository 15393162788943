<template>
  <div>
    <a-spin :spinning="loading">
      <!-- Form to submit feedback with option for anonymity -->
      <h4>Tilbakemelding og Varsling</h4>
      <p>Denne seksjonen lar deg gi tilbakemeldinger og varsler som ledelsen må ta hånd om.<br>
        Gode ideer som bringer firmaet videre vil kunne bli påskjønnet.<br>
        Varslinger vil bli tatt på høyeste alvor og vil bli behandlet i henhold til våre retningslinjer.<br>
        Du kan velge å være anonym eller ikke.</p>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="Anonym">
          <a-switch v-model="form.anonymous" />
          <p>{{ form.anonymous ? 'Skjemaet er anonym. Ledelsen vil ikke kunne vite hvem som har sendt det inn.' : 'Ledelsen vil kunne se at det er du som har sendt inn skjemaet' }}</p>
        </a-form-item>
        <a-form-item label="Melding">
          <a-textarea v-model="form.message" placeholder="Melding" :rows="6"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">Send</a-button>
        </a-form-item>
        </a-form>
    </a-spin>
  </div>
</template>

<script>
import { functions } from '@/services/firebase'
export default {
  data() {
    return {
      form: {
        anonymous: false,
        message: '',
      },
      loading: false,
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.loading = true
      const sendFeedback = functions.httpsCallable('sendFeedback')
      const formFields = {
        sender: this.form.anonymous ? 'Anonym' : this.$store.state.user.name,
        message: this.form.message,
      }
      sendFeedback(formFields)
        .then(() => {
          this.loading = false
          this.form.message = ''
          this.$message.success('Takk for tilbakemeldingen!')
        })
        .catch(() => {
          this.loading = false
          this.$message.error('Noe gikk galt, prøv igjen senere, eller ta kontqakt med IT')
        })
    },
  },
}
</script>
